import { template as template_aa80e8e733af4b2381e7eb0c2b2b7dca } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_aa80e8e733af4b2381e7eb0c2b2b7dca(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
