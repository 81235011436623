import { template as template_be17577e88b447359dd60ab1cc8357be } from "@ember/template-compiler";
const FKLabel = template_be17577e88b447359dd60ab1cc8357be(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
