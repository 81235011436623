import { template as template_308265336d0f4576ae0bd9efc22c5c0c } from "@ember/template-compiler";
const SidebarSectionMessage = template_308265336d0f4576ae0bd9efc22c5c0c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
