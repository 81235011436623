import { template as template_928277548f0c4917a169e044d878a122 } from "@ember/template-compiler";
const WelcomeHeader = template_928277548f0c4917a169e044d878a122(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
